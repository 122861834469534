import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Row, Col, Alert, Button } from 'reactstrap';

import PasswordStrengthBar from 'app/shared/layout/password/password-strength-bar';
import { IRootState } from 'app/shared/reducers';
import { handleRegister, reset } from './register.reducer';

export type IRegisterProps = DispatchProps;

export const RegisterPage = (props: IRegisterProps) => {
  const [password, setPassword] = useState('');

  useEffect(
    () => () => {
      props.reset();
    },
    []
  );

  const handleValidSubmit = (event, values) => {
    props.handleRegister(values.username, values.email, values.firstPassword);
    event.preventDefault();
  };

  const updatePassword = event => setPassword(event.target.value);

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="10">
          <h1 id="register-title">Création de compte</h1>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="10">
          <AvForm id="register-form" onValidSubmit={handleValidSubmit}>
            <AvField
              name="username"
              label="Login"
              placeholder={'Votre login'}
              validate={{
                required: { value: true, errorMessage: 'Votre login est obligatoire.' },
                pattern: {
                  value: '^[a-zA-Z0-9!$&*+=?^_`{|}~.-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$|^[_.@A-Za-z0-9-]+$',
                  errorMessage: 'Votre login est incorrect.',
                },
                minLength: { value: 1, errorMessage: 'Votre login est obligatoire est doit contenir au moins 1 caractère.' },
                maxLength: { value: 50, errorMessage: 'Votre login est obligatoire est doit contenir moins de 50 caractères.' },
              }}
            />
            <AvField
              name="email"
              label="Email"
              placeholder={'Votre email'}
              type="email"
              validate={{
                required: { value: true, errorMessage: 'Votre email est obligatoire.' },
                minLength: { value: 5, errorMessage: 'Votre email est obligatoire et doit contenir au moins 5 caractères' },
                maxLength: { value: 254, errorMessage: 'Votre email est obligatoire et doit contenir moins de 50 caractères.' },
              }}
            />
            <AvField
              name="firstPassword"
              label="Nouveau mot de passe"
              placeholder={'Nouveau mot de passe'}
              type="password"
              onChange={updatePassword}
              validate={{
                required: { value: true, errorMessage: 'Votre mot de passe est obligatoire.' },
                minLength: { value: 4, errorMessage: 'Votre mot de passe est obligatoire et doit contenir au moins 4 caractères.' },
                maxLength: { value: 50, errorMessage: 'Votre mot de passe est obligatoire et doit contenir moins de 50 caractères.' },
              }}
            />
            <PasswordStrengthBar password={password} />
            <AvField
              name="secondPassword"
              label="Confirmation de votre mot de passe"
              placeholder="Confirmation de votre mot de passe"
              type="password"
              validate={{
                required: { value: true, errorMessage: 'La confirmation de votre mot de passe est obligatoire' },
                minLength: { value: 4, errorMessage: 'La confirmation de votre mot de passe est obligatoire doit contenir au moins 4 caractères.' },
                maxLength: { value: 50, errorMessage: 'La confirmation de votre mot de passe est obligatoire doit contenir moins de 50 caractères' },
                match: { value: 'firstPassword', errorMessage: 'Votre mot de passe et sa confirmation doivent être identiques.' },
              }}
            />
            <Button id="register-submit" color="primary" type="submit">
              Créer son compte
            </Button>
          </AvForm>
        </Col>
      </Row>
    </div>
  );
};

const mapDispatchToProps = { handleRegister, reset };
type DispatchProps = typeof mapDispatchToProps;

export default connect(null, mapDispatchToProps)(RegisterPage);
