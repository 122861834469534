import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter,  Row, Col, Label, Alert} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';


export interface IDocumentUpdateModalProps {
  showModal: boolean;
  handleValid: Function;
  handleClose: Function;
  categories:any;
  updating: boolean;
}

export interface IDocumentUpdateModalState {
  selectedFile: File;
}

class DocumentUpdateModal extends React.Component<IDocumentUpdateModalProps, IDocumentUpdateModalState> {
  
  constructor(props) {
    super(props);

    this.setState({selectedFile:undefined})
  }

  handleSubmit = (event, errors, values) => {
    const { handleValid } = this.props;
    const { selectedFile } = this.state;

    handleValid({categorie: values.categorie, file: selectedFile});
  };

  handleChange = (files) =>{
     /* eslint-disable no-console */
    console.log(files)
    this.setState({selectedFile:files[0]})
  }

  render() {
    const { handleClose, showModal, categories, updating} = this.props;

    return (
      <Modal isOpen={showModal} toggle={handleClose} backdrop="static" id="document-delete-page" autoFocus={false}>
        <AvForm model={{}} onSubmit={this.handleSubmit}>
          <ModalHeader toggle={handleClose}>Création d{"'"}un nouveau document</ModalHeader>
          <ModalBody id="coreApp.produit.delete.question">
            <AvGroup>
              <Label id="categorieLabel" for="document-categorie">
                Catégorie
              </Label>
              <AvInput id="document-categorie" type="select"  name="categorie" 
                validate={{
                  required: { value: true, errorMessage: 'Le champ est obligatoire' },
                }}>
                  <option value="empty">--- Sélectionner une catégorie ---</option>
                {categories.map(code => (
                  <option value={code.key} key={code.key}>
                    {code.libelle}
                  </option>
                ))}
              </AvInput>
            </AvGroup>
            <AvGroup>
              <Label id="fileLabel" for="document-file">
                Fichier
              </Label>
              {/* <AvField>
                 <input type="file" id="document-file"   name="file" className="btn btn-primary"/>
              </AvField> */}
          
              <AvField id="document-file" type="file"  name="file" className="btn btn-primary"
                validate={{
                  required: { value: true, errorMessage: 'Le champ est obligatoire' },
                }}
                onChange={ (e) => this.handleChange(e.target.files) }>
              </AvField>
            </AvGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={handleClose}>
              <FontAwesomeIcon icon="ban" />
              &nbsp; Annuler
            </Button>
            <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right ">
              <FontAwesomeIcon icon="save" />
              &nbsp; Enregistrer
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    );
  }
}

export default DocumentUpdateModal;
