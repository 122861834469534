import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getEntity } from './evaluation.reducer';
import moment from 'moment';
import { evaluationEtat } from 'app/config/codifications';


export interface IEvaluationDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const EvaluationDetail = (props: IEvaluationDetailProps) => {
  useEffect(() => {

    props.getEntity(props.match.params.id);

  }, []);

  const { evaluationEntity, match, loading } = props;

  return (

    loading ? (
      <Row className="justify-content-center">
        <Col md="10">
          <p>Chargement des informations de l{"'"}évaluation...</p>
        </Col>
      </Row>
    ) : (
      <Row className="justify-content-center">
        <Col md="10">
            <h2>
              <Button tag={Link} to="/catalogue/evaluation" replace color="info" style={{marginRight:'20px'}}>
                <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Retour</span>
              </Button>
              Evaluation {`"${evaluationEntity.versionReference}"` }
            </h2>

            <fieldset>
              <legend className="legend">Informations générales</legend>
              <dl className="jh-entity-details">
                <dt><span id="numero">Numéro</span></dt>
                <dd>{evaluationEntity.numero}</dd>

                <dt><span id="refVersion">Référence Version</span></dt>
                <dd>{evaluationEntity.versionReference}</dd>

                <dt><span id="date">Date</span></dt>
                <dd>{moment(evaluationEntity.dateExecution).format('DD/MM/YYYY hh:mm:ss')}</dd>

                <dt><span id="description">Créateur</span></dt>
                <dd>{evaluationEntity.initiateur}</dd>

                <dt><span id="etat">Etat</span></dt>
                <dd>{evaluationEntity.etat}</dd>

                <dt><span id="duree">Durée</span></dt>
                <dd>{evaluationEntity.duree} ms</dd>

                { evaluationEntity.etatCode === evaluationEtat.ECHEC && (
                  <>
                    <dt><span id="message">Erreur</span></dt>
                    <dd>{evaluationEntity.errorMessage}</dd>
                  </>
                )}
              </dl>
            </fieldset>

            <fieldset>
              <legend className="legend">Variables évaluées</legend>
              <dl className="jh-entity-details">
                {evaluationEntity.variables && evaluationEntity.variables.length > 0 && (
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Nature</th>
                        <th>Code</th>
                        <th>Formule/Valeur par défaut</th>
                        <th>Résultat</th>
                      </tr>
                    </thead>
                    <tbody>
                      {evaluationEntity.variables.map((variable, i) => (
                        <tr key={`entity-${i}`}>
                          <td>{variable.nature}</td>
                          <td title={variable.code}>
                              {variable.code.length > 40 ? variable.code.substring(0,36) + "..." :variable.code }
                          </td>
                          <td title={variable.valeur}>
                              { variable.valeur && variable.valeur.length > 40 ? variable.valeur.substring(0,36) + "..." : variable.valeur }                         
                          </td>
                          <td>{variable.errorMessage ? variable.errorMessage : variable.resultat}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
                {!evaluationEntity.variables || (evaluationEntity.variables && evaluationEntity.variables.length === 0) && ("Aucune variable évaluée")}
              </dl>
            </fieldset>

            <fieldset>
              <legend className="legend">Formules évaluées</legend>
              <dl className="jh-entity-details">
                {evaluationEntity.formules && evaluationEntity.formules.length > 0 && (
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Nature</th>
                        <th>Formule</th>
                        <th>Résultat</th>
                      </tr>
                    </thead>
                    <tbody>
                      {evaluationEntity.formules.map((formule, i) => (
                        <tr key={`entity-${i}`}>
                          <td>{formule.natureCode}</td>
                          <td title={formule.valeur}>{ formule.valeur && formule.valeur.length > 30 ? formule.valeur.substring(0,29) + "..." :formule.valeur }</td>
                          <td title={formule.errorMessage}>{formule.errorMessage ? formule.errorMessage.substring(0,15) : formule.resultat}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
                {!evaluationEntity.formules || (evaluationEntity.formules && evaluationEntity.formules.length === 0) && ("Aucune formule évaluée")}
              </dl>
            </fieldset>
        </Col>
      </Row>
    )
  );
};

const mapStateToProps = ({ evaluation }: IRootState) => ({
  evaluationEntity: evaluation.entity,
  loading: evaluation.loading
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EvaluationDetail);
