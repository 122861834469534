import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter,  Row, Col, Label, Alert} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { IVersion } from 'app/shared/model/version.model';
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import "@webscopeio/react-textarea-autocomplete/style.css";


export interface IVersionPublishModalProps {
  showModal: boolean;
  handleValid: Function;
  handleClose: Function;
  entity: IVersion;
  updating: boolean;
}


class VersionPublishModal extends React.Component<IVersionPublishModalProps> {

  constructor(props) {
    super(props);

    this.close = this.close.bind(this);
  }



  handleSubmit = (event, errors, values) => {
    const { handleValid } = this.props;
    if (errors.length === 0){
      handleValid(values);
    }
  };


  close = () => {
    this.props.handleClose();
  }

  render() {
    const { showModal, updating, entity} = this.props;

    return (
      <Modal style={{minWidth:'1000px'}} isOpen={showModal} toggle={() => this.close()} backdrop="static" id="version-publish-page" autoFocus={false}>
        <AvForm model={entity} onSubmit={this.handleSubmit}>
          <ModalHeader toggle={() => this.close()}>{"Publier la version"}</ModalHeader>
          <ModalBody id="coreApp.version.publish.question">
            <Row className="justify-content-center">
              <Col md="10">
                <Alert color="info" style={{marginTop:'10px'}}>
                    Attention :<br />
                    Une fois publiée, une version n{"'"}est plus modifiable. Automatiquement, un nouveau brouillon sera créé.
                  </Alert>

                <AvGroup>
                  <Label id="datePublicationLabel" for="version-publication-date">
                    Date de publication
                  </Label>
                  <AvInput id="version-publication-date" type="datetime-local"  name="publicationDate" />
                </AvGroup>
                <AvGroup>

                  <Label id="noteLabel" for="version-note">
                    Note
                  </Label>
                  <AvInput id="version-note" type="textarea"  name="note" rows="5"
                    validate={{
                      required: { value: true, errorMessage: 'Le champ est obligatoire' },
                    }}>
                  </AvInput>
                </AvGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.close()}>
              <FontAwesomeIcon icon="ban" />
              &nbsp; Annuler
            </Button>
            <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right ">
              <FontAwesomeIcon icon="save" />
              &nbsp; Publier
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    );
  }
}

export default VersionPublishModal;
