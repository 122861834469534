import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter,  Row, Col, Label, Alert} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { IFormule } from 'app/shared/model/formule.model';
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import "@webscopeio/react-textarea-autocomplete/style.css";


export interface IFormuleUpdateModalProps {
  showModal: boolean;
  handleValid: Function;
  handleClose: Function;
  types:any;
  entity: IFormule;
  updating: boolean;
  variablesVersion: any;
}

export interface IFormuleUpdateModalState {
  formule:string;
}

class FormuleUpdateModal extends React.Component<IFormuleUpdateModalProps, IFormuleUpdateModalState> {
  readonly state: IFormuleUpdateModalState = { formule:undefined };

  constructor(props) {
    super(props);

    this.setState({
      formule: props.entity.formule
    })
  }

  componentDidUpdate(prevProps) {
    let state = {};

    if (this.props.entity !== prevProps.entity) {
      state = {
        ...state, 
        formule: this.props.entity.formule
      };
    }

    if (Object.keys(state).length>0) {
      this.setState({
        ...state
      })
    }
  }

  handleSubmit = (event, errors, values) => {
    const { handleValid } = this.props;
    const { formule } = this.state;

    if (errors.length === 0){
      values.formule = formule;

      this.setState({
        formule: undefined
      })

      handleValid(values);
    }
  };

  changeFormule = (formuleSaisie) =>{
    this.setState({formule: formuleSaisie})
  };

  render() {
    const { handleClose, showModal, types, updating, entity, variablesVersion} = this.props;
    const { formule } = this.state;
    const isNew = !entity || (entity && !entity.id)
    const Item = ({ entity: { name, char } }) => <div>{`${name}: ${char}`}</div>;
    const Loading = ({ data }) => <div>Loading</div>;

    return (
      <Modal isOpen={showModal} toggle={handleClose} backdrop="static" id="formule-update-page" autoFocus={false}>
        <AvForm model={isNew ? {}:entity} onSubmit={this.handleSubmit}>
          <ModalHeader toggle={handleClose}>{isNew ? "Création d'une nouvelle formule" : "Modification d'une formule"}</ModalHeader>
          <ModalBody id="coreApp.produit.delete.question">
            <AvGroup>
              <Label id="typesLabel" for="formule-type">
                Type
              </Label>
              <AvInput id="formule-type" type="select"  name="typeCode"
                validate={{
                  required: { value: true, errorMessage: 'Le champ est obligatoire' },
                }}>
                  <option value="empty">--- Sélectionner un type ---</option>
                {types.map(code => (
                  <option value={code.key} key={code.key}>
                    {code.libelle}
                  </option>
                ))}
              </AvInput>
            </AvGroup>
            <AvGroup>
              <Label id="nomLabel" for="formule-nom">
                Nom
              </Label>
              <AvField
                  id="formule-nom"
                  type="text"
                  name="nom"
                  validate={{
                    required: { value: true, errorMessage: 'Le champ est obligatoire.' },
                  }}
                />
            </AvGroup>
            <AvGroup>
              <Label id="descriptionLabel" for="formule-description">
                Description
              </Label>
              <AvField
                  id="formule-description"
                  type="textarea"
                  name="description"
                  validate={{
                    required: { value: true, errorMessage: 'Le champ est obligatoire.' },
                  }}
                />
            </AvGroup>

            <AvGroup>
              <Label id="formuleLabel" for="formule-formule">
                Formule de calcul
              </Label>
              <ReactTextareaAutocomplete
                id="formule-formule"  name="formule"
                onChange={ (e) => this.changeFormule(e.target.value) }
                className="my-textarea"
                style={{
                  fontSize: "15px",
                  lineHeight: "20px",
                  padding: 5
                }}
                value={this.state.formule}
                loadingComponent={Loading}
                minChar={0}
                placeholder="Veuillez saisir votre formule de calcule"
                rows={5}
                trigger={{
                  "$": {
                    dataProvider(token) {
                      return variablesVersion.filter(variable => ("${" + variable.code + "}").includes(token))
                        .map(variable => ({ name: variable.nom, char:"${" + variable.code + "}" }));
                    },
                    component: Item,
                    output: (item, trigger) => item.char
                  }
                }}
              />
            </AvGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={handleClose}>
              <FontAwesomeIcon icon="ban" />
              &nbsp; Annuler
            </Button>
            <Button color="primary" id="save-entity" type="submit" disabled={updating} className="float-right ">
              <FontAwesomeIcon icon="save" />
              &nbsp; Enregistrer
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    );
  }
}

export default FormuleUpdateModal;
