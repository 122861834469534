import './footer.scss';

import React from 'react';

import { Col, Row } from 'reactstrap';

const Footer = props => (
  <div className="footer page-content bg-primary" style={{height:'100%'}}>
    <Row>
      <Col md="5" className="text-center" style={{color:'#FFFFFF'}}>
      <h3>Contactez-nous<br />
      Nous sommes à votre écoute !</h3>
      </Col>
      <Col md="2">
        Groupe Roederer<br />
        2 rue Bartisch<br />
        67024 Strasbourg cedex 1
      </Col>
      <Col md="2">
      Tél. +33(0)3 88 76 73 00<br />
      Fax. +33(0)3 88 76 73 10<br />
      E-mail : <a href="mailto:roederer@roederer.fr">roederer@roederer.fr</a>
      </Col>
      <Col md="3">
      Orias<br />
      N° 07 000 336
      </Col>
      </Row>
   

  </div>
);

export default Footer;
