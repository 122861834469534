import React from 'react';
import {Badge, Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {AvField, AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import {IVariable} from 'app/shared/model/variable.model';
import {isVariableMatricielle, variableNature, variableType} from 'app/config/codifications'
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import "@webscopeio/react-textarea-autocomplete/style.css";
import {CSVLink} from 'react-csv';
import {ExcelRenderer} from 'react-excel-renderer';

export interface IVariableUpdateModalProps {
  showModal: boolean;
  handleValid: Function;
  handleClose: Function;
  types: any;
  natures: any;
  entity: IVariable;
  updating: boolean;
  variablesVersion: any;
}

export interface IVariableUpdateModalState {
  formatedNom: string;
  selectedType: string;
  selectedNature: string;
  liste: string;
  formule: string;
  matriceHeaders: string[];
  matriceBodies: string[];
  matriceValues: string[];
}


class VariableUpdateModal extends React.Component<IVariableUpdateModalProps, IVariableUpdateModalState> {
  readonly state: IVariableUpdateModalState = {
    formatedNom: "",
    selectedType: "",
    selectedNature: "",
    liste: "",
    formule: undefined,
    matriceHeaders: [],
    matriceBodies: [],
    matriceValues: []
  };
  const

  constructor(props) {
    super(props);

    const criteres = props.entity.criteres ? props.entity.criteres.split("#") : [];

    this.setState({
      formatedNom: this.formatNom(props.entity.nom),
      liste: props.entity.liste ? props.entity.liste.replaceAll(";", "\n") : "",
      selectedType: props.entity.typeCode,
      selectedNature: props.entity.natureCode,
      formule: props.entity.formule,
      matriceHeaders: criteres,
      matriceBodies: this.initMatriceBodies(props.variablesVersion, criteres),
      matriceValues: this.decomposeFormule(props.entity.formule)
    })

    this.changeCode = this.changeCode.bind(this);
    this.changeType = this.changeType.bind(this);
    this.changeCriteres = this.changeCriteres.bind(this);
    this.close = this.close.bind(this);
  }

  decomposeFormule = (formule) => {
    // {"vp#bleu#garage":"1","vp#rouge#garage":"2","vp#jaune#garage":"3","vpp#bleu#garage":"4","vpp#rouge#garage":"5","vpp#jaune#garage":"6","vp#bleu#public":"7","vp#rouge#public":"8","vp#jaune#public":"9","vpp#bleu#public":"10","vpp#rouge#public":"11","vpp#jaune#public":"12"}["${critere_1}#${critere_2}#${critere_3}"]
    if (!formule) {
      return [];
    }

    return Object.values(JSON.parse(formule)).map(val => val + "");
  }

  recomposeFormule = (matriceBodies, values) => {

    let result = "{"
    for (let index = 0; index < matriceBodies.length; index++) {
      result += "\"" + matriceBodies[index] + "\":\"" + (values[index] ? values[index] : "") + "\",";
    }
    result = result.substring(0, result.length - 1) + "}";

    return result;
  }

  initMatriceBodies = (variables, current) => {

    if (!variables)
      return [];

    if (!current)
      return [];

    const concernedVariables = []
    current.forEach(codeVariable => {

      concernedVariables.push(variables.filter(variable => variable.code === codeVariable)[0])
    });
    const listValeurs = concernedVariables.reduce((acc, curr) => {
      acc.push(curr.liste.split(";"))
      return acc;
    }, []);

    const f = (a, b) => [].concat(...a.map(aMap => b.map(bMap => [].concat(aMap, bMap))));
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const cartesian = (a, b, ...c) => b ? cartesian(f(a, b), ...c) : a;
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const output = cartesian(...listValeurs);

    if (!output) {
      return []
    }

    return output.reduce((acc, curr) => {
      if (Array.isArray(curr)) {
        acc.push(curr.join('#'))
      } else {
        acc.push(curr)
      }
      return acc;
    }, []);
  }

  componentDidUpdate(prevProps) {
    let state = {};

    if (this.props.entity.nom !== prevProps.entity.nom) {
      state = {...state, formatedNom: this.formatNom(this.props.entity.nom)};
    }

    if (this.props.entity.liste !== prevProps.entity.liste) {
      state = {...state, liste: this.props.entity.liste ? this.props.entity.liste.replace(/;/g, "\n") : ""};
    }

    if (this.props.entity.typeCode !== prevProps.entity.typeCode) {
      state = {...state, selectedType: this.props.entity.typeCode};
    }

    if (this.props.entity.natureCode === variableNature.CALCULEE && this.props.entity.formule !== prevProps.entity.formule) {
      state = {...state, formule: this.props.entity.formule};
    }

    if (this.props.entity.natureCode === variableNature.CALCULEE_MATRICE && this.props.entity.formule !== prevProps.entity.formule) {
      state = {...state, matriceValues: this.decomposeFormule(this.props.entity.formule)};
    }

    if (this.props.entity.natureCode !== prevProps.entity.natureCode) {
      state = {...state, selectedNature: this.props.entity.natureCode};
    }

    if (this.props.entity.criteres && this.props.entity.criteres !== prevProps.entity.criteres) {
      state = {
        ...state,
        matriceHeaders: this.props.entity.criteres.split("#"),
        matriceBodies: this.initMatriceBodies(this.props.variablesVersion, this.props.entity.criteres.split("#"))
      };
    }

    if (Object.keys(state).length > 0) {
      this.setState({
        ...state
      })
    }
  }

  formatNom = (value) => {
    const rules = {
      a: "àáâãäå",
      A: "ÀÁÂ",
      e: "èéêë",
      E: "ÈÉÊË",
      i: "ìíîï",
      I: "ÌÍÎÏ",
      o: "òóôõöø",
      O: "ÒÓÔÕÖØ",
      u: "ùúûü",
      U: "ÙÚÛÜ",
      y: "ÿ",
      c: "ç",
      C: "Ç",
      n: "ñ",
      N: "Ñ"
    };

    if (value) {
      value = value.replaceAll(' ', '_').replaceAll('-', '_').replaceAll("'", '');
      Object.keys(rules).forEach(rule => {
        for (const car of rules[rule]) {
          value = value.replaceAll(car, rule);
        }
      })

      return value.toUpperCase();
    }
    return "";
  }

  exportedMatrice = () => {
    const {matriceBodies, matriceHeaders, matriceValues} = this.state;

    const results = []
    for (let indexBody = 0; indexBody < matriceBodies.length; indexBody++) {
      const template = {};
      for (let index = 0; index < matriceHeaders.length; index++) {
        template[matriceHeaders[index]] = matriceBodies[indexBody].split('#')[index];
      }
      template["valeur"] = matriceValues[indexBody];
      results.push(template)
    }

    return results;
  };

  fileHandler = (fileObj) => {
    const {matriceValues} = this.state;

    ExcelRenderer(fileObj[0], (err, resp) => {
      if (err) {
        // eslint-disable-next-line no-console
        console.log("Error" + err);
      } else {
        const newRows = [];
        // eslint-disable-next-line no-debugger
        debugger;
        resp.rows.forEach((row, index) => {
          if (index > 0)
            matriceValues[index - 1] = row[row.length - 1];
        })

        this.setState({matriceValues})
      }
    });
  }

  handleSubmit = (event, errors, values) => {
    const {handleValid} = this.props;
    const {liste, formule, matriceBodies, matriceHeaders, matriceValues} = this.state;

    if (errors.length === 0) {
      if (liste) {
        values.liste = liste.replace(/\n/g, ";");
      }

      if (values.natureCode === variableNature.CALCULEE) {
        values.typeCode = variableType.STRING;
        values.obligatoire = true;
        values.formule = formule;
      } else if (values.natureCode === variableNature.CALCULEE_MATRICE) {
        values.typeCode = variableType.STRING;
        values.criteres = matriceHeaders.join("#");
        values.obligatoire = true;
        values.formule = this.recomposeFormule(matriceBodies, matriceValues);
      }

      this.setState({
        formatedNom: "",
        liste: "",
        formule: undefined,
        matriceValues: [],
        matriceBodies: [],
        matriceHeaders: []
      })

      handleValid(values);
    }
  };

  changeCode = (target) => {
    this.setState({
      formatedNom: this.formatNom(target.value)
    })
  };

  changeFormule = (formuleSaisie) => {
    this.setState({formule: formuleSaisie})
  };

  changeFormuleMatrice = (index, formuleSaisie) => {

    const {matriceValues} = this.state;
    matriceValues[index] = formuleSaisie;
    this.setState({matriceValues})
  };


  changeType = (value) => {
    this.setState({
      selectedType: value
    })
  };

  changeNature = (value) => {
    this.setState({
      selectedNature: value
    })
  };

  changeListe = (value) => {
    this.setState({
      liste: value
    })
  }

  changeCriteres = (target) => {
    const {matriceHeaders} = this.state;


    if (target.getAttribute("class").indexOf("success") >= 0) {
      for (let i = 0; i < matriceHeaders.length; i++) {

        if (matriceHeaders[i] === target.innerText) {
          matriceHeaders.splice(i, 1);
        }
      }
    } else {
      matriceHeaders.push(target.innerText);
    }


    this.setState({
      matriceValues: [],
      matriceHeaders,
      matriceBodies: this.initMatriceBodies(this.props.variablesVersion, matriceHeaders)

    })
  }


  close = () => {
    this.setState({
      formatedNom: "",
      liste: "",
      formule: undefined,
      matriceValues: [],
      matriceBodies: [],
      matriceHeaders: []
    })

    this.props.handleClose();
  }

  render() {
    const {showModal, types, natures, updating, entity, variablesVersion} = this.props;
    const {formatedNom, selectedType, selectedNature, liste, matriceHeaders, matriceBodies, matriceValues} = this.state;
    const isNew = entity.id === 0 || !entity.id
    const Item = ({entity: {name, char}}) => <div>{`${name}: ${char}`}</div>;
    const Loading = ({data}) => <div>Loading</div>;

    return (
      <Modal style={{minWidth: '1000px'}} isOpen={showModal} toggle={() => this.close()} backdrop="static"
             id="variable-update-page" autoFocus={false}>
        <AvForm model={isNew ? {} : entity} onSubmit={this.handleSubmit}>
          <ModalHeader
            toggle={() => this.close()}>{isNew ? "Création d'une nouvelle variable" : "Modification d'une variable"}</ModalHeader>
          <ModalBody id="coreApp.version.delete.question">
            <Row className="justify-content-center">
              <Col md="5">
                <AvGroup>
                  <Label id="naturesLabel" for="variable-nature">
                    Nature
                  </Label>
                  <AvInput id="variable-nature" type="select" name="natureCode"
                           onChange={(e) => this.changeNature(e.target.value)}
                           disabled={!isNew}
                           validate={{
                             required: {value: true, errorMessage: 'Le champ est obligatoire'},
                           }}>
                    <option value="empty">--- Sélectionner une nature ---</option>
                    {natures.map(code => (
                      <option value={code.key} key={code.key}>
                        {code.libelle}
                      </option>
                    ))}
                  </AvInput>
                </AvGroup>
                {selectedNature !== variableNature.CALCULEE && selectedNature !== variableNature.CALCULEE_MATRICE && (
                  <AvGroup>
                    <Label id="typesLabel" for="variable-type">
                      Type
                    </Label>
                    <AvInput id="variable-type" type="select" name="typeCode"
                             onChange={(e) => this.changeType(e.target.value)}
                             disabled={!isNew}
                             validate={{
                               required: {value: true, errorMessage: 'Le champ est obligatoire'},
                             }}>
                      <option value="empty">--- Sélectionner un type ---</option>
                      {types.map(code => (
                        <option value={code.key} key={code.key}>
                          {code.libelle}
                        </option>
                      ))}
                    </AvInput>
                  </AvGroup>
                )}
                <AvGroup>
                  <Label id="nomLabel" for="variable-nom">
                    Nom
                  </Label>
                  <AvField
                    id="variable-nom"
                    type="text"
                    readOnly={!isNew}
                    name="nom"
                    validate={{
                      required: {value: true, errorMessage: 'Le champ est obligatoire.'},
                    }}
                    onChange={(e) => this.changeCode(e.target)}
                  />
                </AvGroup>
                {selectedNature !== variableNature.CALCULEE && selectedNature !== variableNature.CALCULEE_MATRICE && (
                  <AvGroup>
                    <Label id="typesLabel" for="variable-mandatory">
                      Obliagtoire
                    </Label>
                    <AvInput id="variable-mandatory" type="select" name="obligatoire"
                             validate={{
                               required: {value: true, errorMessage: 'Le champ est obligatoire'},
                             }}>
                      <option value="empty">--- Sélectionner une valeur ---</option>
                      <option value="true">Oui</option>
                      <option value="false">Non</option>
                    </AvInput>
                  </AvGroup>
                )}

                {selectedNature !== variableNature.CALCULEE_MATRICE && (
                  <>
                    <AvGroup>
                      <Label id="codeLabel" for="variable-code">
                        Code
                      </Label>
                      <AvField
                        id="variable-code"
                        type="text"
                        readOnly={true}
                        value={formatedNom}
                        name="code"
                        validate={{
                          required: {value: true, errorMessage: 'Le champ est obligatoire.'},
                        }}
                      />
                    </AvGroup>
                    <AvGroup>
                      <Label id="descriptionLabel" for="variable-description">
                        Description
                      </Label>
                      <AvField
                        id="variable-description"
                        type="textarea"
                        name="description"
                      />
                    </AvGroup>
                  </>
                )}
              </Col>
              <Col md="5">
                {selectedNature === variableNature.CALCULEE_MATRICE && (
                  <>
                    <AvGroup>
                      <Label id="codeLabel" for="variable-code">
                        Code
                      </Label>
                      <AvField
                        id="variable-code"
                        type="text"
                        readOnly={true}
                        value={formatedNom}
                        name="code"
                        validate={{
                          required: {value: true, errorMessage: 'Le champ est obligatoire.'},
                        }}
                      />
                    </AvGroup>
                    <AvGroup>
                      <Label id="descriptionLabel" for="variable-description">
                        Description
                      </Label>
                      <AvField
                        id="variable-description"
                        type="textarea"
                        name="description"
                      />
                    </AvGroup>
                  </>
                )}
                {selectedNature === variableNature.CALCULEE && (
                  <AvGroup>
                    <Label id="formuleLabel" for="variable-formule">
                      Formule de calcul
                    </Label>
                    <ReactTextareaAutocomplete
                      id="variable-formule" name="formule"
                      onChange={(e) => this.changeFormule(e.target.value)}
                      className="my-textarea"
                      style={{
                        fontSize: "15px",
                        lineHeight: "20px",
                        padding: 5
                      }}
                      value={this.state.formule}
                      loadingComponent={Loading}
                      minChar={0}
                      placeholder="Veuillez saisir votre formule de calcule"
                      rows={5}
                      trigger={{
                        "$": {
                          dataProvider(token) {
                            return variablesVersion.filter(variable => ("${" + variable.code + "}").includes(token))
                              .map(variable => ({name: variable.nom, char: "${" + variable.code + "}"}));
                          },
                          component: Item,
                          output: (item, trigger) => item.char
                        }
                      }}
                    />
                  </AvGroup>
                )}
                {selectedNature !== variableNature.CALCULEE && selectedNature !== variableNature.CALCULEE_MATRICE && selectedType && (
                  <>
                    <AvGroup>
                      <Label id="defaultValueLabel" for="variable-defaultValue">
                        {selectedNature === variableNature.CONSTANTE && "Valeur de la constante"}
                        {selectedNature !== variableNature.CONSTANTE && "Valeur par défaut"}
                      </Label>

                      {selectedType === variableType.STRING && (
                        <AvInput placeholder="Veuillez saisir du texte" id="variable-defaultValue" type="text"
                                 name="defaultValue"/>
                      )}

                      {selectedType === variableType.BOOLEAN && (
                        <AvInput placeholder="Veuillez sélectionner une valeur" id="variable-defaultValue" type="select"
                                 name="defaultValue">
                          <option value=""></option>
                          <option value="true">Vrai</option>
                          <option value="false">Faux</option>
                        </AvInput>
                      )}

                      {selectedType === variableType.DATE && (
                        <AvInput placeholder="Veuillez choisir une date" id="variable-defaultValue" type="date"
                                 name="defaultValue"/>
                      )}

                      {selectedType === variableType.NUMBER && (
                        <AvInput placeholder="Veuillez choisir un nombre" id="variable-defaultValue" type="number"
                                 name="defaultValue"/>
                      )}
                    </AvGroup>

                    {selectedNature !== variableNature.CONSTANTE && selectedType === variableType.STRING && (
                      <AvGroup>
                        <Label id="listeLabel" for="variable-liste">
                          Liste de valeurs autorisées
                        </Label>
                        <AvInput id="variable-liste" type="textarea" name="liste" rows={5} value={liste}
                                 onChange={(e) => this.changeListe(e.target.value)}/>
                      </AvGroup>
                    )}
                  </>
                )}
              </Col>
            </Row>
            {selectedNature === variableNature.CALCULEE_MATRICE && (
              <Row>
                <Col md="12">
                  <AvGroup>
                    {variablesVersion.filter(variable => variable.liste !== '' && variable.liste).map((variable, i) => (
                      <Badge onClick={(e) => this.changeCriteres(e.target)} style={{margin: '10px', cursor: 'pointer'}}
                             color={matriceHeaders.includes(variable.code) ? "success" : "secondary"} pill
                             key={`header-${i}`}>{variable.code}</Badge>
                    ))}
                    {matriceHeaders && matriceHeaders.length > 0 && (
                      <div style={{
                        width: '100%',
                        overflowX: 'auto',
                        maxHeight: '400px'
                      }}>
                        <Table id="matrice">
                          <thead>
                          <tr>
                            {matriceHeaders && matriceHeaders.map((header, i) => (
                              <th key={`header-${i}`}>{header}</th>
                            ))}
                            <th>Valeur</th>
                          </tr>
                          </thead>
                          <tbody>
                          {matriceBodies && matriceBodies.map((body, i) => (
                            <tr key={`body-line-${i}`}>
                              {body.split("#").map((val, j) => (
                                <td key={`body-val-${j}`}>{val}</td>
                              ))}

                              <td>
                                <ReactTextareaAutocomplete
                                  id={`variable-matrice-val-${i}`} name={`matriceVal_${i}`}
                                  onChange={(e) => this.changeFormuleMatrice(i, e.target.value)}
                                  className="my-textarea"
                                  style={{
                                    fontSize: "15px",
                                    lineHeight: "20px",
                                    padding: 5
                                  }}
                                  value={matriceValues[i]}
                                  loadingComponent={Loading}
                                  minChar={0}
                                  placeholder="Veuillez saisir une valeure ou une formule de calcul"
                                  rows={2}
                                  trigger={{
                                    "$": {
                                      dataProvider(token) {
                                        return variablesVersion.filter(variable => ("${" + variable.code + "}").includes(token))
                                          .map(variable => ({name: variable.nom, char: "${" + variable.code + "}"}));
                                      },
                                      component: Item,
                                      output: (item) => item.char
                                    }
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                          </tbody>
                        </Table>
                      </div>
                    )}
                  </AvGroup>
                </Col>
              </Row>
            )}
          </ModalBody>
          <ModalFooter>
            {isVariableMatricielle(selectedNature) && matriceBodies.length > 0 && (
              <>
                <span style={{float: 'left'}}>
                  <CSVLink
                    data={this.exportedMatrice()}
                    filename={'matrice.csv'}
                    separator={';'}
                    enclosingCharacter={''}
                    className="btn btn-info"
                    target="_blank"
                  >
                    Export Excel
                  </CSVLink>
                </span>

                <span style={{float: 'left'}}>
                  <input type="file" className="btn btn-primary"
                         onChange={(e) => this.fileHandler(e.target.files)}/>
                </span>
              </>
            )}
            <Button color="secondary" onClick={() => this.close()}>
              <FontAwesomeIcon icon="ban"/>
              &nbsp; Annuler
            </Button>
            <Button color="info" id="save-entity" type="submit" disabled={updating} className="float-right ">
              <FontAwesomeIcon icon="save"/>
              &nbsp; Enregistrer
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    );
  }
}

export default VariableUpdateModal;
