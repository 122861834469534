import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { IRootState } from 'app/shared/reducers';
import { getEntities, searchEntities } from './evaluation.reducer';
import { AUTHORITIES } from 'app/config/constants';
import moment from 'moment';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { evaluationEtat } from 'app/config/codifications';

export interface IEvaluationProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const Evaluation = (props: IEvaluationProps) => {
  useEffect(() => {
     // props.getEntities();
  }, []);

  const handleSearch = (event, errors, values) => {
    props.searchEntities(values);
  };

  const { evaluationList, match, loading, isConseiller } = props;
  return (
   
    <fieldset>
    <legend className="legend">Liste des évaluations</legend>

    <div className="alert alert-info">
      <AvForm model={{numero:'', nom:'', creationDateFrom:'', creationDateTo:'', produit:'', createur:''}} onSubmit={handleSearch}>
        <Row className="justify-content-left">
          <Col md="2">
            <AvGroup>
              <Label id="noteLabel" for="version-note">
                Numéro
              </Label>
              <AvInput id="version-note" type="text"  name="numero" />
            </AvGroup>
          </Col>
          <Col md="2">
            <AvGroup>
              <Label id="noteLabel" for="version-note">
                Créateur
              </Label>
              <AvInput id="version-note" type="text"  name="createur" />
            </AvGroup>
          </Col>
          <Col md="2">
            <AvGroup>
              <Label id="noteLabel" for="version-note">
                Produit
              </Label>
              <AvInput id="version-note" type="text"  name="produit" />
            </AvGroup>
          </Col>
          <Col md="2">
            <AvGroup>
              <Label id="noteLabel" for="version-note">
                Etat
              </Label>
              <AvInput id="version-note" type="select"  name="etat">
                <option value="">Tous</option>
                <option value={evaluationEtat.SUCCESS}>Réussie</option>
                <option value={evaluationEtat.ECHEC}>Echoué</option>
              </AvInput>
              </AvGroup>
          </Col>
          <Col md="2">
            <AvGroup>
              <Label id="noteLabel" for="version-note">
                Créé depuis le
              </Label>
              <AvInput id="version-note" type="date"  name="creationDateFrom" />  
            </AvGroup>
          </Col>
          <Col md="2">
            <AvGroup>
              <Label id="noteLabel" for="version-note">
                Jusqu{"'"}au
              </Label>
              <AvInput id="version-note" type="date"  name="creationDateTo" />  
            </AvGroup>
          </Col>
        </Row>
        <Row className="justify-content-right">
          <Col md="12">
          <Button color="primary" id="save-entity" type="submit" className="float-right ">
            <FontAwesomeIcon icon="search" />
            &nbsp; Rechercher
          </Button>
          </Col>
        </Row>
      </AvForm>
    </div>
        {loading &&  <div className="alert alert-warning">Recherche en cours</div>}
        {!loading && 
          evaluationList && evaluationList.length ===0 && <div className="alert alert-warning">Aucune évaluation trouvée</div>
        }
        {
          !loading && !evaluationList && <div className="alert alert-warning">Veuillez lancer la recherche</div>
        } 
        
        { !loading && evaluationList && evaluationList.length > 0 && (
          <Table responsive>
            <thead>
              <tr>
                <th>Numéro</th>
                <th>Date d{"'"}exécution</th>
                <th>Produit</th>
                <th>Version</th>
                <th>Etat</th>
                <th>Créateur</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {evaluationList.map((evaluation, i) => (
                <tr key={`entity-${i}`}>
                  <td>{evaluation.numero}</td>
                  <td>{moment(evaluation.dateExecution).format("DD/MM/YYYY hh:mm:ss")}</td>
                  <td>{evaluation.produit}</td>
                  <td>{evaluation.versionReference}</td>
                  <td>{evaluation.etat}</td>
                  <td>{evaluation.initiateur}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${evaluation.id}`} color="info" size="sm" style={{marginRight:'5px'}}>
                        <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">Consulter</span>
                      </Button>

                      {!isConseiller && (
                        <Button tag={Link} to={`${match.url}/${evaluation.id}/delete`} color="danger" size="sm">
                          <FontAwesomeIcon icon="trash" /> <span className="d-none d-md-inline">Supprimer</span>
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </fieldset>
  );
};

const mapStateToProps = ({ evaluation, authentication }: IRootState) => ({
  evaluationList: evaluation.entities,
  loading: evaluation.loading,
  isConseiller: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.CONSEILLER])
});

const mapDispatchToProps = {
  getEntities,
  searchEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Evaluation);
